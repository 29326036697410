.titleSliderProduct {
  width: 300px;
  height: 50px;
}

.productCardSkeleton {
  width: 230px !important;
  height: 400px;
  border-radius: 12px;
  position: relative;
  margin: 0 10px;
}

.buttonSliderProductSkeleton {
  margin-top: 10px;
  height: 3.5em;
  width: 180px;
  border-radius: 50px;
}

.sliderProductBlockWrapper {
  padding: 60px 0;
}

.sliderWrapper {
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.sliderProductBlockWrapper:nth-child(even) {
  background-color: rgb(231, 231, 231);
}

@media (max-width:1140px) {
  .productCardSkeleton:nth-child(5) {
    display: none !important;
  }
}

@media (max-width:960px) {
  .productCardSkeleton:nth-child(4) {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .titleSliderProduct {
    width: 230px;
    height: 35px;
  }
}

@media (max-width:640px) {
  .productCardSkeleton:nth-child(3) {
    display: none !important;
  }
}

@media (max-width:420px) {
  .productCardSkeleton:nth-child(2) {
    display: none !important;
  }
}