/* STYLES ITEMS */

.itemContainer {
  display: flex;
  flex-direction: column;
  width: 70px;
  gap: 4px;
  cursor: pointer;
}

.wrapperIcon {
  width: 70px;
  height: 70px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 15px;
  border: 1px solid #e9e9e9;
}

.label {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;
  color: #797979;
  font-family: ggsr;
}

/* CONTAINER */
.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: auto;
  flex-wrap: wrap;
  gap: 4px;
}

.root::-webkit-scrollbar {
  display: none;
}

/* MOST POPULAR */
.rootPopular {
  padding-left: var(--mobile-container-spacing);
  background-color: #ffff;
  padding-top: 15px;
  padding-bottom: 20px;
  margin-top: 25px;
}

.popularContainer {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
}

.popularContainer::-webkit-scrollbar {
  display: none;
}

.popularItemContainer {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  width: 128px;
  border-radius: 8px;
  border: 1px solid #e9e9e9;
  background-color: #ffff;
  padding: 12px 8px;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

.popularItemContainer + .popularItemContainer {
  margin-left: 11px;
}

.imagesPopular {
  padding-top: 5px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.labelPopular {
  text-align: center;
  color: #797979;
}

.imagesPopular {
  padding-top: 5px;
  padding-bottom: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.labelPopular {
  text-align: center;
  color: #797979;
}

/* GROUP PRODUCT BLOCK  */

.headerProduct {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.headerProductTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.textHeader {
  font-family: ggsm;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-transform: capitalize;
  color: #000000;
}

.wrapperTimer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.wrapperLink,
.mobileWrapperLink {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobileWrapperLink {
  width: 100%;
}

.viewMore {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #0e1983;
  line-height: 18px;
  text-align: center;
  margin: 0px;
  margin-top: 5px;
}

/* SurveyForm */
.rootSurvey {
  background-color: #ffffff;
  padding: 15px 25px;
  margin-bottom: 120px;
  margin: 20px 15px 120px 15px;
  border-radius: 15px;
}

.radioGroupSurvey {
  display: 'flex';
  flex-direction: row;
  justify-content: space-between;
}

.radioGroupSurvey span {
  font-family: ggsm;
}

.formControlSurvey {
  width: 100%;
}

.btnSubmitSurvey {
  background: #0cba69;
  color: #ffffff;
  font-size: 14px;
  padding: 3px;
  margin: 0px !important;
  margin-top: 5px !important;
  font-family: ggsm !important;
}

.btnSubmitSurvey:hover {
  background: #0cba69;
}

.btnSurveyDisabled {
  background-color: #dddddd;
  border-color: #dddddd !important;
  outline: #dddddd;
}

.linkToSurvey {
  margin-top: 12px;
  border-bottom: 1.5px solid #0e1983;
  color: #0e1983;
  font-family: ggsm;
}

.megaSale_container {
  position: relative;
  color: #fff;
  text-align: center;
}

.megaSale_nameContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
  display: flex;
  gap: 4px;
  font-size: 13px;
  align-items: center !important;
}
.wrapper {
  border: 1px solid #dcdbdb;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 40px;
  overflow: hidden;
  position: relative;
  padding: 0;
  width: 100%;
}

.wrapper input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  padding: 11px 8px;
  color: #797979;
  /* margin-right: 32px; */
}
.icon {
  margin-left: 12px;
  height: 16px;
  width: 16px;
}
