.container [class*='slick-slide '] {
  height: inherit !important;
}

/* STYLE DOTS */

.container [class='banner-slider-dots'] button {
  outline: none;
  font-size: 0px;
  border: none;
  background-color: transparent;
}

.container [class='slick-active'] button::after {
  background-color: var(--primary-color);
}

.container [class='banner-slider-dots'] button::after {
  content: '';
  height: 6px;
  width: 6px;
  background-color: gray;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  transition: 0.2s;
}

.container [class='slick-active'] button::after {
  background-color: var(--primary-color);
}

.container [class='banner-slider-dots'] {
  position: absolute;
  padding: 0px;
  display: flex;
  left: 50%;
  bottom: -13px;
  transform: translateX(-50%);
}

.container [class='banner-slider-dots'] li {
  list-style: none;
  width: 12px;
}

.container [class*='slick-list'] {
  font-size: unset;
}

/* STYLE BANNER */
/* #E9E9E9; */

.wrapper_slide {
  padding-top: 5px;
  border-radius: 12px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
}

.wrapper_slide span {
  width: 100% !important;
}

.wrapper_slide_image {
  object-fit: contain;
}

/* STYLE PRODUCT PREVIEW */
.container [class*='slick-prev'],
.container [class*='slick-next'] {
  font-size: 20px;
  z-index: 20;
  color: unset;
}

.container [class*='slick-prev']::before,
.container [class*='slick-next']::before {
  content: '';
  position: relative;
}

.container [class*='slick-next'] {
  right: 8px;
}

.container [class*='slick-prev'] {
  left: 8px;
}

.section__item {
  scroll-snap-align: start;
  width: calc(100% - 15px);
  background-color: pink;
  height: 300px;
  margin-right: 15px;
}

.arrowIcon {
  color: #fff;
  position: absolute;
  width: 28px !important;
  z-index: 3;
  border-radius: 50%;
  background-color: rgb(151 153 155 / 50%);
  height: 28px !important;
}

.arrowRight {
  left: -15px;
}

.arrowLeft {
  right: -15px;
}

.emptyProduct {
  font-family: ggsr;
  text-align: center;
  margin: 15px 0px;
  font-size: 15px;
}

.wrapperProductPreview {
  display: flex !important;
  padding: 10px 10px;
  gap: 10px;
  flex-direction: row;
}

.store_arrowRight {
  left: -8px !important;
}

.store_arrowLeft {
  right: -15px !important;
}

.mobileGroupProduct_block [class*='swiper-button-prev'],
.mobileGroupProduct_block [class*='swiper-button-next'] {
  width: 28px;
  height: 28px;
}

.mobileGroupProduct_block [class*='swiper-button-prev']::after,
.mobileGroupProduct_block [class*='swiper-button-next']::after {
  display: none !important;
}

.mobileGroupProduct_block > div > div,
.mobileGroupProduct_block > div {
  height: inherit !important;
}

.mobileGroupProduct_block > div > div > .wrapperProductPreview {
  height: 100%;
}

/* slider on seller page */
.mobileBestProdSeller_block {
  padding: 32px 0;
}

.mobileBestProdSeller_block > div > [class*='swiper-slide-active'] {
  transform: scale(1.1);
  transition: 1.2s all;
}

.mobileBestProdSeller_block > div > div,
.mobileProductSeller_block > div > div,
.mobileBestProdSeller_block > div,
.mobileProductSeller_block > div {
  height: inherit !important;
}

.customSwiper_prev {
  left: 15px;
}

.customSwiper_prev::after,
.customSwiper_next::after {
  display: none;
}

.customSwiper_next {
  right: 15px;
}

/* css for best product */
.mobileBestProdSeller_block > div > [class*='swiper-slide-prev'] {
  left: -1rem;
}

.mobileBestProdSeller_block > div > [class*='swiper-slide-next'] {
  right: -1rem;
}

.mobileProductSeller_block .customSwiper_prev {
  left: -12px !important;
  width: 32px;
  height: 32px;
}

.mobileProductSeller_block .customSwiper_next {
  right: -12px !important;
  width: 32px;
  height: 32px;
}

/* custome arrow */
.customSwiper_prev {
  left: 15px;
  -webkit-tap-highlight-color: transparent;
}

.customSwiper_next:visited {
  box-shadow: none;
}

.customSwiper_next {
  right: 15px;
  -webkit-tap-highlight-color: transparent;
}

.customSwiper_next > div,
.customSwiper_prev > div {
  padding: 0;
}

.mySwiper {
  width: 80%;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .mySwiper {
    width: 100%;
  }
}

.mySwiper [class*='swiper-button-prev'],
.mySwiper [class*='swiper-button-next'] {
  width: 28px;
  height: 28px;
  color: #fff;
  position: absolute;
  z-index: 3;
  border-radius: 50%;
  background-color: rgb(151 153 155 / 50%);
}

.mySwiper [class*='swiper-button-prev']::after,
.mySwiper [class*='swiper-button-next']::after {
  font-size: 16px;
}
