/* @media only screen and (max-width: 768px) {
  .wrapper_media {
    margin-bottom: 37px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1367px) and (orientation: landscape) {
  .wrapper_media {
    margin-bottom: 43px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1025px) and (orientation: portrait) {
  .wrapper_media {
    margin-bottom: 43px;
  }
} */

.progress {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.bestProducts {
  padding: 20px 16px;
  background: #09884d;
  border: 1px solid #e9e9e9;
  border-radius: 12px 12px 0px 0px;
}

.title {
  text-align: center;
  margin-top: 0 !important;
  font-size: 32px;
  color: #00b46e;
  font-weight: 500;
}

.root {
  max-width: 555px;
  height: 224px;
  margin: 8px !important;
  box-shadow: 0 0 18px -10px !important;
}

.media {
  height: 0 !important;
  padding-top: 56.25% !important;
}

.large {
  width: 80px !important;
  height: 80px !important;
}

.card_header {
  padding-bottom: 0 !important;
  padding-top: 24px;
}

.header_customer {
  position: relative;
  top: -15px;
  font-size: 20px !important;
  color: #00b46e;
  font-weight: bold !important;
}

.header_title {
  position: relative;
  top: -15px;
  font-size: 16px !important;
  color: #919aa3 !important;
}

.wrapper_media {
  background-color: #f4f7fc;
}

.wrapper_media_container {
  max-width: 1240px;
  margin: 0 auto;
}

.wrapper_media:nth-child(2n) {
  background-image: linear-gradient(to right, #00b46e, #17a2b8);
}

.wrapper_media:nth-child(2n) .progress * {
  color: #fff !important;
}

.wrapper_media:nth-child(2n) .title {
  color: #fff;
}

.card_content {
  position: relative;
  top: -20px;
  padding-left: 112px !important;
  padding-right: 20px !important;
  padding-top: 0 !important;
}

.comment_style {
  font-size: 16px !important;
  font-style: italic;
}

.rotate {
  transform: rotate(180deg);
}

.quote {
  font-size: 16px !important;
  color: #00b46e;
  opacity: 0.2;
}

.seeAllWrapper {
  display: flex;
  justify-content: center;
}

.seeAll {
  display: inline-flex !important;
  justify-content: center;
  text-align: center;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: 1px solid #00b46e !important;
  padding: 0.4rem 1.25rem !important;
  font-size: 1rem !important;
  color: #fff;
  background-color: #00b46e;
  border-radius: 50px !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 500;
  line-height: 2;
  align-self: center;
  min-width: 200px;
}

.seeAll:hover {
  color: #fff;
  background-color: #0ea86d;
  border-color: #00b46e;
}

.wrapper_media:nth-child(2n) .seeAll {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
}

.wrapper_media:nth-child(2n) .seeAllWrapper .seeAll:hover {
  color: #212529 !important;
  background-color: #fff !important;
}

.SliderProductWrap {
  padding: 48px 0;
}

@media (max-width: 767px) {
  .SliderProductWrap h2 {
    font-size: 24px;
    color: #212529;
  }
}

.SliderProductWrapV2 {
  padding: 20px 0;
}

.header {
  display: flex;
  justify-content: space-between;
}

.name {
  font-size: 28px;
  font-weight: 500;
  margin-left: 10px;
  font-family: googlesansmedium;
  width: 100%;
  word-wrap: break-word;
}

.bestProductsName {
  color: #fff;
  text-transform: uppercase;
  width: calc(100% - 160px);
}

@media (max-width: 767px) {
  .name {
    font-size: 20px;
  }

  .header {
    padding: 0 10px;
    align-items: center;
  }
}

.view_more {
  display: flex;
  justify-content: center;
}

.view_more p {
  font-size: 16px;
  font-family: googlesansmedium;
}

.wrapper {
  border: 1px solid #e9e9e9;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 18px 30px;
  margin-bottom: 50px;
}

.bestProductsWrapper {
  padding: 0 !important;
}

.bestProducts_view_more {
  margin-left: auto;
  display: flex;
  align-items: center;
  flex-wrap: no-wrap;
}

.bestProducts_view_more a {
  color: #fff;
  font-size: 16px;
  font-family: googlesansmedium;
}

.bestProducts_view_more a span:first-child {
  transform: translateY(-2px);
  margin-right: 10px;
  display: inline-block;
}

.icon_name {
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-bottom: 10px;
  justify-content: space-between;
}

@media (max-width: 476px) {
  .bestProducts_view_more a {
    font-size: 14px;
    line-height: 1;
  }

  .name {
    width: calc(100% - 50px);
  }

  .bestProducts_view_more a span:first-child {
    transform: translateY(-2px);
    margin-right: 0px;
    margin-left: 10px;
  }

  .bestProducts_view_more img {
    display: none !important;
  }
}


/* style using for mobile v2 */

.wrapper_mv2 {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  padding: 10px 10px;
  margin-bottom: 20px;
}

.view_more_mv2 p {
  font-size: 14px;
}

.icon_name_mv2 {
  padding-bottom: 5px;
  padding-top: 10px;
}

.name_mv2 {
  font-size: 16px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  gap: 8px
}

.megaSale_name {
  display: flex;
  align-items: center;
  justify-content: center;
}
