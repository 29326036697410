.root {
  height: 375px;
  border-radius: 9px;
  flex: 1;
}

.wrapperContent {
  margin-top: 10px;
  padding: 13px;
  display: flex;
  flex-direction: row;

}