.boxModal {
  z-index: 1000 !important;
}
.boxInput {
  position: absolute;
  top: 0;
  transform: translate(-50%, 0);
  width: 100%;
  height: 100%;
}
.mobileModalSearch_root {
  background-color: rgba(0, 0, 0, 0);
}
