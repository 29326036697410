.searchDropdown {
  position: absolute;
  width: 100%;
  max-width: 100%;
  max-height: calc(100% - 48px);
  overflow-y: scroll;
  z-index: 610;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  left: 0;
  z-index: 98;
}

.searchResults {
  padding: 8px 0px!important;
  border-bottom: 1px solid rgba(195, 204, 220, 0.4);
  display: block !important;
  color: #343a40;
  transition: color 0.2s, background-color 0.2s;
  font-size: 16px !important;
  word-break: break-word;
}
.searchResult_empty{
  padding: 8px 16px!important;
  border-bottom: 1px solid rgba(195, 204, 220, 0.4);
  display: block !important;
  color: #343a40;
  transition: color 0.2s, background-color 0.2s;
  font-size: 16px !important;
  word-break: break-word;
}

.searchResults:hover {
  background-color: #f8f9fa;
  color: #00b46e;
  text-decoration: none;
  transition: color 0.2s, background-color 0.2s;
}

.searchResults:hover a {
  color: #00b46e !important;
}

.textPrimary {
  color: #00b46e !important;
  font-weight: 500;
}

.searchDropdown_container{
  margin-bottom: 8px;
}

.searchDropdown_name{
  font-family: ggsr;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  color: #343a40
}

.searchDropdown_price{
  text-align: right;
  font-family: ggsr;
  font-weight: 500;
  font-size: 16px;
  color: #00b46e;
}
